import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import LineChart from "./LineChart";
import { apiService } from "../services/apiservices";
import { useContext, useEffect, useState } from "react";
import { config } from "../config/config";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import { globalContext } from "../GlobalState/GlobalContext";
import UserDetails from "./UserDetails";
const Dashboard = () => {
    const globalState = useContext(globalContext)
    const [showUserDetails, setShowUserDetails] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [usersList, setUsersList] = useState([])
    const [page, setPage] = useState(1)
    const [selectedUserType, setSelectedUserType] = useState("");
    // const [totalUsers, setTotalUsers] = useState(0)
    // const [totalPages, setTotalPages] = useState(1)
    // const [actionStatus, setActionStatus] = useState({})
    // const [totalCreators, setTotalCreators] = useState(0)
    // const [totalBrands, setTotalBrands] = useState(0)
    // const [pendingRequests, setPendingRequests] = useState(0)
    const options = [
        { value: "", label: "All" },
        { value: "creator", label: "Creators" },
        { value: "brand", label: "Brands" },
    ];

    useEffect(() => {
        fetchPendingList(selectedUserType,page)
        handleDataCount()
    }, [])

    const fetchPendingList = async (userType, page) => {
        // console.log(userType,page)
        try {
            const response = await apiService.getPendingList(userType || "", page);
            setUsersList(response.data);
        
        } catch (error) {
            console.log("Error Fetching Users List", error);
            globalState.setUsersList([])
        }
    }

    const handleAccept = async (userId) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        });
        const confirmResult = await swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You want to approve this user!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, approve it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
        });
        if (confirmResult.isConfirmed) {
            try {
                const params = {
                    userId: userId,
                    isApproved: "accept"
                }
                await apiService.userApproval(params);
                fetchPendingList(selectedUserType,page);

                Swal.fire("Approved!", "User successfully approved.", "success");
            } catch (error) {
                console.log("Failed to enable user");
            }
        } else {
            swalWithBootstrapButtons.fire(
                "Cancelled",
                "User rejection was cancelled.",
                "error"
            );
        }
    }

    const handleReject = async (userId) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        });
        const confirmResult = await swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You want to reject this user!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, reject it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
        });

        if (confirmResult.isConfirmed) {
            const { value: rejectReason } = await Swal.fire({
                title: "Enter Rejection Reason",
                input: "textarea",
                // inputLabel: "Reason",
                inputPlaceholder: "Enter the reason for rejection",
                // inputValidator: (value) => {
                //     if (!value) {
                //         return "You need to provide a reason!";
                //     }
                // },
                preConfirm: () => {
                    const input = Swal.getInput();
                    if (!input.value) {
                        Swal.showValidationMessage("You need to provide a reason!");
                        setTimeout(() => {
                            const swalValidationBox = document.querySelector('.swal2-validation-message');
                            if (swalValidationBox) swalValidationBox.style.display = 'none';
                        }, 5000);
            
                        return false;
                    }
                    return input.value;
                },
                showCancelButton: true,
            });
            if (rejectReason) {

                try {
                    const params = {
                        userId: userId,
                        isApproved: "reject",
                        rejectReason: rejectReason,
                    }
                    await apiService.userApproval(params);
                    fetchPendingList(selectedUserType,page);
                    Swal.fire("Rejected!", "User successfully rejected.", "success");
                } catch (error) {
                    console.log("Failed to disable user");
                }
            };
        }
        else {
            swalWithBootstrapButtons.fire(
                "Cancelled",
                "User rejection was cancelled.",
                "error"
            );
        }
    }

    const handlePageChange = async (page) => {
        setPage(page);
        fetchPendingList(selectedUserType,page)
    }

    const handleDataCount = async () => {
        try {
            const response = await apiService.getCountData()
            globalState.setTotalCreators(response.data.totalCreator)
            globalState.setTotalBrands(response.data.totalBrand)
            globalState.setPendingRequests(response.data.pendingRequest)
            console.log(response.data)
        } catch (error) {
            console.log("Failed to get Count")
        }
    }

    const handleShowDetails = (user) => {
        setSelectedUser(user);
        setShowUserDetails(true);
    };

    const handleCloseDetails = () => {
        setShowUserDetails(false);
        setSelectedUser(null);
    };

    const handleSelectionChange = (selectedOption) => {
        setSelectedUserType(selectedOption ? selectedOption.value : "");
        setPage(1);
        fetchPendingList(selectedOption.value, 1)

    }
    return (
        <>
            <UserDetails
                show={showUserDetails}
                onHide={handleCloseDetails}
                selectedUser={selectedUser}
            />
            <section className="common-area">
                <Container fluid>
                    <div className="dashboard-area">
                        <h6>Good Morning,</h6>
                        <p>Streamline workflows, connect with partners, and drive impactful campaigns.</p>
                        <img src={require("../assets/images/dashboard-banner.png")} />
                    </div>
                    <Row className="creater-dashboard-area">
                        <Col md={3}>
                            <div className="total-creaters-area">
                                <div>
                                    <p>Total Creators</p>
                                    <h5>{globalState.totalCreators}</h5>
                                </div>
                                <img src={require("../assets/images/image1.png")} />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="total-creaters-area">
                                <div>
                                    <p>Total Brands</p>
                                    <h5>{globalState.totalBrands}</h5>
                                </div>
                                <img src={require("../assets/images/image2.png")} />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="total-creaters-area">
                                <div>
                                    <p>Pending Request</p>
                                    <h5>{globalState.pendingRequests}</h5>
                                </div>
                                <img src={require("../assets/images/image3.png")} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="chart-area">
                                <LineChart />
                            </div>

                        </Col>
                        <Col md={6}>
                            <div className="request-listing">
                                <div className="request-listing-top">
                                    <h6>New Request Listing</h6>
                                    <Form.Group
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Select options={options} placeholder="Type" onChange={handleSelectionChange}/>
                                    </Form.Group>
                                </div>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>User</th>
                                            <th>Email</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {usersList?.list?.length > 0 ? (
                                            usersList?.list?.map((user) => (
                                                <tr key={user._id}>
                                                    <td>
                                                        <div className="user-type-area" onClick={() => handleShowDetails(user)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img src={user.image ? `${config.imageUrl}${user.image}` : require("../assets/images/user-avtar.png")} alt="Image" />
                                                            {/* {console.log(`${config.imageUrl}${user.image}`)} */}
                                                            <div>
                                                                <h6>{user.role === "brand" ? user.brandName || "N/A" : `${user.firstName} ${user.lastName}` || "N/A"}</h6>
                                                                <p>{user.role || "N/A"}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{user.email}</td>
                                                    <td>{user.createdAt ? new Date(user.createdAt).toLocaleDateString() : "N/A"}</td>
                                                    {/* <td>{user.isActive ? "Active" : "Inactive"}</td> */}
                                                    {/* <td style={{ color: "goldenrod" }}>{user.isApproved}</td> */}
                                                    <td>
                                                        <div className="action-btns">
                                                            <Button type="button" variant="unset" className="approve-btn" onClick={() => handleAccept(user._id)} disabled={globalState.actionStatus[user._id]}> Approve </Button>
                                                            <Button type="button" variant="unset" className="reject-btn" onClick={() => handleReject(user._id)} disabled={globalState.actionStatus[user._id]}> Reject </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5">
                                                    <div className="no-data-area">
                                                        <img src={require("../assets/images/no-data.gif")} />
                                                        <h6> No users found.</h6>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}

                                    </tbody>
                                </Table>
                                
                                    <Pagination
                                        activePage={page}
                                        itemsCountPerPage={5}
                                        totalItemsCount={usersList?.totalUsers > 5 ? usersList?.totalUsers :0 }
                                        pageRangeDisplayed={2}
                                        onChange={(page) => handlePageChange(page)}
                                        hideDisabled={true}
                                        prevPageText={"Prev"}
                                        nextPageText={"Next"}
                                    />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};
export default Dashboard;