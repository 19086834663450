import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../component/Login";
import Dashboard from "../component/Dashboard";
import CommonLayout from "../common/CommonLayout";
import UserManagement from "../component/UserManagement";
import Creators from "../component/Creaters";
import Brands from "../component/Brands";
import NotifiedUserManagement from "../component/NotifiedUser";
import ProtectedRoute from "../component/ProtectedRoute";


const Routing = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Login />}
        ></Route>
        <Route
          path="/dashboard"
          element={<ProtectedRoute><CommonLayout>{<Dashboard />}</CommonLayout></ProtectedRoute>}
        ></Route>
        {/* <Route
          path="/creators"
          element={<ProtectedRoute><CommonLayout>{<Creators />}</CommonLayout></ProtectedRoute>}
        ></Route>
        <Route
          path="/brands"
          element={<ProtectedRoute><CommonLayout>{<Brands />}</CommonLayout></ProtectedRoute>}
        ></Route> */}
        <Route path="/user-management" element={<ProtectedRoute><CommonLayout>{<UserManagement />}</CommonLayout></ProtectedRoute>} />
        {/* <Route path="/user-management/:userType" element={<CommonLayout>{<UserManagement />}</CommonLayout>} /> */}
        <Route
          path="/notified-user-management"
          element={<ProtectedRoute><CommonLayout>{<NotifiedUserManagement />}</CommonLayout></ProtectedRoute>}
        ></Route>
      </Routes>
    </Router>
  );
};

export default Routing;
