import React, { useState } from 'react'
import { globalContext } from './GlobalContext'

function GlobalStates({ children }) {
  const [usersList, setUsersList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalUsers, setTotalUsers] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [actionStatus, setActionStatus] = useState({})
  const [totalCreators, setTotalCreators] = useState(0)
  const [totalBrands, setTotalBrands] = useState(0)
  const [pendingRequests, setPendingRequests] = useState(0)
  const [userManagementList, setUserManagementList] = useState([]);
  const [searchBar, setSearchBar] = useState("");
  const [showModal, setShowModal] = useState(false)
  const [reason, setReason] = useState()
  const [notifiedUsers, setNotifiedUsers] = useState([]);

  const states = {
    usersList, setUsersList,
    currentPage, setCurrentPage,
    totalUsers, setTotalUsers,
    totalPages, setTotalPages,
    actionStatus, setActionStatus,
    totalCreators, setTotalCreators,
    totalBrands, setTotalBrands,
    pendingRequests, setPendingRequests,
    userManagementList, setUserManagementList,
    searchBar, setSearchBar,
    showModal, setShowModal,
    reason, setReason,
    notifiedUsers, setNotifiedUsers
  }
  return (
    <globalContext.Provider value={states}>
      {children}
    </globalContext.Provider>
  )
}

export default GlobalStates