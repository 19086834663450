import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { apiService } from "../services/apiservices";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
// import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard", { replace: true });
    }
  }, [navigate]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
    }),

    onSubmit: async (values) => {
      setLoading(true);
      console.log("Form submitted with values:", values);

      try {
        let params = {
          email: values.email,
          password: values.password,
        }
        const response = await apiService.adminLogin(params)
        const token = response.data.data;
        console.log("ssssss>>>>>>>>>", token)

        if (token) {
          localStorage.setItem('token', token)
        }

        console.log("Login Successful", response.data);
        // window.location.href = '/dashboard';
        navigate('/dashboard')
        await Swal.fire("Login Successful!", "You have logged in successfully", "success");

      } catch (error) {
        console.error("Login failed", error);

        await Swal.fire({
          title: "Login Failed",
          text: error?.response?.data?.message || "An unexpected error occurred.Please try again!",
          icon: "error",
        });

      } finally {
        setLoading(false);
      }
    },
  })
  return (
    <>
      {/* Launch page start */}
      <section className="login-section launch-section">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={5} lg={6}>
              <img src={require('../assets/images/main-logo.svg').default} alt="Logo || Brandfluence" className="login-logo" />
              <div className="login-area">
                <h1>Login</h1>
                <Form noValidate onSubmit={formik.handleSubmit}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter Email" name="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={!!formik.errors.email && formik.touched.email} />
                    <Form.Control.Feedback
                      type="invalid"
                      className="text-danger"
                    >
                      {formik.errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-5 relative" controlId="formBasicEmail">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <Form.Control type="password" placeholder="Enter Password" name="password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={!!formik.errors.password && formik.touched.password} />
                      {/* <Form.Control type={showPassword ? "text" : "password"} placeholder="Enter Password" name="password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={!!formik.errors.password && formik.touched.password}/> */}
                      {/* <i className="fa-solid fa-eye eyes-icon"></i> */}
                      {/* <i className="fa-solid fa-eye-slash"></i> */}
                      <Form.Control.Feedback
                        type="invalid"
                        className="text-danger"
                      >
                        {formik.errors.password}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Button type="submit" variant="unset" disabled={loading}>
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Login"
                    )}
                  </Button>
                </Form>
              </div>
            </Col>

          </Row>
        </Container>
      </section>
      {/* Launch page end */}
    </>
  );
};
export default Login;