// LineChart.js
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from "chart.js";
import { apiService } from "../services/apiservices";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const LineChart = () => {
  const [chartData, setChartData] = useState()
  useEffect(() => {
    handleChartData()
  }, [])

  const handleChartData = async () => {
    try {
      const response = await apiService.getChartData()
      setChartData(response.data.response)
      console.log(response.data.response)
    } catch (error) {

    }
  }
  let data
  if (chartData) {

    const days = [...new Set(chartData.map((item) => item.day))];
    const creatorsData = chartData
      .filter((item) => item.role === "creator")
      .map((item) => item.count);
    const brandsData = chartData
      .filter((item) => item.role === "brand")
      .map((item) => item.count);

    data = {
      labels: days,
      datasets: [
        {
          label: "Creators",
          data: creatorsData,
          borderColor: "#042AFC",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderWidth: 2,
          tension: 0.4, // Smooth line
        },
        {
          label: "Brands",
          data: brandsData,
          borderColor: "#5587d3",
          borderDash: [10],
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderWidth: 3,
          tension: 0.4, // Smooth line
        },
      ],
    };
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        // align: "end",
        labels: {
          usePointStyle: true, // Enables point style for legend
          pointStyle: "rectRounded", // Sets the point style to a circle
          font: {
            size: 12
          },
          color: 'black', // Customize text color  
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Days",
        },
      },
      y: {
        title: {
          display: true,
          text: "Counts",
        },
      },
    },
  };

  return (
    <>
      <h6>Weekly Request Receiving</h6>
      <div style={{ position: "relative", height: "370px" }}>
        {chartData && <Line data={data} options={options} />}
      </div>
    </>
  );
};

export default LineChart;
