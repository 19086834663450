import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
const Header = () => {
    return (
        <>
            {/* Header */}
            <header>
                <Navbar expand="lg" className="">
                    <Container fluid>
                        <Navbar.Brand href="#">
                            <img src={require('../assets/images/logo.png')} alt="Left image || Brandfluence" className="" />
                        </Navbar.Brand>
                        {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
                        {/* <Navbar.Collapse id="navbarScroll">
                            <Nav
                                className="ms-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px' }}
                                navbarScroll
                            >
                                <Nav.Link href="#"><img src={require("../assets/images/main-profile.png")} /></Nav.Link>
                            </Nav>
                           
                        </Navbar.Collapse> */}
                    </Container>
                </Navbar>
            </header>
        </>
    )
};
export default Header;