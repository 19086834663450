import React from 'react';
import { Offcanvas, Form, Row, Col } from "react-bootstrap";

function UserDetails({ show, onHide, selectedUser }) {
    return (
        <Offcanvas show={show} onHide={onHide} placement="end" className="creator-offcanvas">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                {selectedUser && (
                    <Form>
                        {selectedUser.role === "creator" ? (<Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>First name</Form.Label>
                                    <Form.Control type="text" value={selectedUser.firstName || ""} readOnly />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Last name</Form.Label>
                                    <Form.Control type="text" value={selectedUser.lastName || ""} readOnly />
                                </Form.Group>
                            </Col>
                        </Row>) : <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Brand Name</Form.Label>
                            <Form.Control type="text" value={selectedUser?.brandName || ""} readOnly />
                        </Form.Group>}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" value={selectedUser.email || ""} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="text" value={selectedUser.phoneNumber || ""} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Social Links</Form.Label>
                            <Form.Control type="text" value={selectedUser.socialLinks || ""} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Most active platform</Form.Label>
                            <Form.Control type="text" value={selectedUser.activePlatform || ""} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Are you actively posting videos as an TikTok shop affiliate? </Form.Label>
                            <Form.Control type="text" value={selectedUser?.isActiveTikTokAffiliate === true ? "Yes" : "No"} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>How many brands are you looking to work with monthly?</Form.Label>
                            <Form.Control type="text" value={selectedUser?.monthlyBrandGoal || ""} readOnly />
                        </Form.Group>
                        {selectedUser.role === "brand" ? (<Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>How many affiliates / creators are you looking to get</Form.Label>
                            <Form.Control type="text" value={selectedUser.count_creator_looking} readOnly />
                        </Form.Group>) : ""}
                    </Form>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default UserDetails;
