import React from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap'

function RejectReasonModal({ show, onHide, textReason }) {
    return (
        <Modal show={show} onHide={onHide} centered className='reject-reason-modal'>
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    Rejected Reason
                </Modal.Title>
            </Modal.Header>
            <ModalBody>
                <p>{textReason || ""}</p>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onHide}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}

export default RejectReasonModal