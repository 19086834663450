import { Button, Container, Dropdown, Form, Table } from "react-bootstrap";
import Select from "react-select";
import { apiService } from "../services/apiservices";
import { useContext, useEffect, useState } from "react";
import { config } from "../config/config";
import Pagination from "react-js-pagination";
import { globalContext } from "../GlobalState/GlobalContext";
import RejectReasonModal from "./RejectReasonModal";
import UserDetails from "./UserDetails";
const NotifiedUserManagement = () => {
    const globalState = useContext(globalContext)
    const [showUserDetails, setShowUserDetails] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserType, setSelectedUserType] = useState("");
    const [notifiedUsers, setNotifiedUsers] = useState([]);
    const [searchBar, setSearchBar] = useState("");
    const [page, setPage] = useState(1)
    // const [currentPage, setCurrentPage] = useState(1)
    // const [totalUsers, setTotalUsers] = useState(0)
    // const [totalPages, setTotalPages] = useState(1)

    const options = [
        { value: "", label: "All" },
        { value: "creator", label: "Creators" },
        { value: "brand", label: "Brands" },
    ];

    useEffect(() => {
        fetchUsers(selectedUserType, page, searchBar);
    }, [searchBar, selectedUserType]);

    const fetchUsers = async (userType, page, searchKey) => {
        try {
            const response = await apiService.getLandingPageList(userType || "", page, searchKey);
            setNotifiedUsers(response.data)
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const handleSelectionChange = (selectedOption) => {
        setSelectedUserType(selectedOption ? selectedOption.value : "");
        setPage(1);
        fetchUsers(selectedOption.value, 1, searchBar)

    }

    const handlePageChange = async (page) => {
        setPage(page);
        fetchUsers(selectedUserType, page, searchBar)
    }

    const handleSearchBar = (e) => {
        setSearchBar(e.target.value);
        setPage(1);
        fetchUsers(selectedUserType, 1, e.target.value)

    };

    const handleShowReason = (reason) => {
        globalState.setReason(reason)
        globalState.setShowModal(true)
    }

    const handleShowDetails = (user) => {
        setSelectedUser(user);
        setShowUserDetails(true);
    };

    const handleCloseDetails = () => {
        setShowUserDetails(false);
        setSelectedUser(null);
    };

    return (
        <>
            <UserDetails
                show={showUserDetails}
                onHide={handleCloseDetails}
                selectedUser={selectedUser}
            />
            <section className="common-area">
                <Container fluid>
                    <div className="head-area">
                        <h3>Notified User</h3>
                        <div className="search-area">
                            {/* <div className="head-search">
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchBar}
                                    onChange={handleSearchBar}
                                    className=" mr-sm-2"
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M9.58341 17.5C13.9557 17.5 17.5001 13.9556 17.5001 9.58332C17.5001 5.21107 13.9557 1.66666 9.58341 1.66666C5.21116 1.66666 1.66675 5.21107 1.66675 9.58332C1.66675 13.9556 5.21116 17.5 9.58341 17.5Z" stroke="#1F253C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18.3334 18.3333L16.6667 16.6667" stroke="#1F253C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div> */}
                            <Form.Group
                                controlId="exampleForm.ControlInput1"
                            >
                                {/* <Form.Label>Sort By</Form.Label> */}
                                <Select options={options} placeholder="Type" onChange={handleSelectionChange} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="user-maagement-table">
                        <Table>
                            <thead>
                                <tr>
                                    <th>User Name</th>
                                    <th>Type</th>
                                    <th>Email</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notifiedUsers?.list?.length > 0 ? (
                                    notifiedUsers?.list?.map((user, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="user-type-area" onClick={() => handleShowDetails(user)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <img src={user.image ? `${config.imageUrl}${user.image}` : require("../assets/images/user-avtar.png")} alt="Image" />
                                                    {user.role === "brand" ? user.brandName || "N/A" : `${user.firstName} ${user.lastName}` || "N/A"}
                                                </div>
                                            </td>
                                            <td>{user.role || "N/A"}</td>
                                            <td>{user.email || "N/A"}</td>
                                            <td>{user.createdAt ? new Date(user.createdAt).toLocaleDateString() : "N/A"}</td>
                                            <td style={{
                                                color:
                                                    user.isApproved === 'accept' ? 'green' :
                                                        user.isApproved === 'pending' ? 'goldenrod' :
                                                            user.isApproved === 'reject' ? 'red' : ''
                                            }}>
                                                {user.isApproved === "accept" ? "Accept" :
                                                    user.isApproved === "pending" ? "Pending" :
                                                        user.isApproved === "reject" ? "Reject" : ""
                                                }
                                                {/* <Button type="button" variant="unset" className="enable-btn">Enable</Button>
                                                <Button type="button" variant="unset" className="disable-btn">Disable</Button> */}
                                            </td>
                                            <td>{user.isApproved === "reject" ? <Button className="view-reason-btn" onClick={() => handleShowReason(user.rejectReason)}>View Reason</Button> : "-"}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">
                                            <div className="no-data-area">
                                                <img src={require("../assets/images/no-data.gif")} />
                                                <h6> No users found.</h6>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>

                    <Pagination
                        activePage={page}
                        itemsCountPerPage={10}
                        totalItemsCount={notifiedUsers?.totalUsers > 10 ? notifiedUsers?.totalUsers : 0}
                        pageRangeDisplayed={2}
                        onChange={(page) => handlePageChange(page)}
                        hideDisabled={true}
                        prevPageText={"Prev"}
                        nextPageText={"Next"}
                    />
                </Container>
                <RejectReasonModal
                    textReason={globalState.reason}
                    show={globalState.showModal}
                    onHide={() => { globalState.setShowModal(false) }} />
            </section>
        </>
    );
};
export default NotifiedUserManagement