import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

const Sidebar = () => {
    const navigate = useNavigate()
    const { userType } = useParams();
    const location = useLocation()

    const isActive = (path) => location.pathname === path;

    const handleLogout = async () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        });
        const confirmResult = await swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You want to log out!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, log out!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
        });
        if (confirmResult.isConfirmed) {
            localStorage.clear()
            Swal.fire("Logged Out!", "You have logged out successfully.", "success");
            navigate('/')
        } else {
            swalWithBootstrapButtons.fire(
                "Cancelled",
                "Logout was cancelled.",
                "error"
            );
        }
    }
    return (
        <>
            <section className="sidebar">
                <ul>
                    <li className={isActive("/dashboard") ? "active" : ""}><Link to="/dashboard"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M6.03332 1.66666H4.44999C2.62499 1.66666 1.66666 2.62499 1.66666 4.44166V6.02499C1.66666 7.84166 2.62499 8.79999 4.44166 8.79999H6.02499C7.84166 8.79999 8.79999 7.84166 8.79999 6.02499V4.44166C8.80832 2.62499 7.84999 1.66666 6.03332 1.66666Z" fill="#042AFC" />
                        <path d="M15.5583 1.66666H13.975C12.1583 1.66666 11.2 2.62499 11.2 4.44166V6.02499C11.2 7.84166 12.1583 8.79999 13.975 8.79999H15.5583C17.375 8.79999 18.3333 7.84166 18.3333 6.02499V4.44166C18.3333 2.62499 17.375 1.66666 15.5583 1.66666Z" fill="#042AFC" />
                        <path d="M15.5583 11.1917H13.975C12.1583 11.1917 11.2 12.15 11.2 13.9667V15.55C11.2 17.3667 12.1583 18.325 13.975 18.325H15.5583C17.375 18.325 18.3333 17.3667 18.3333 15.55V13.9667C18.3333 12.15 17.375 11.1917 15.5583 11.1917Z" fill="#042AFC" />
                        <path d="M6.03332 11.1917H4.44999C2.62499 11.1917 1.66666 12.15 1.66666 13.9667V15.55C1.66666 17.375 2.62499 18.3333 4.44166 18.3333H6.02499C7.84166 18.3333 8.79999 17.375 8.79999 15.5583V13.975C8.80832 12.15 7.84999 11.1917 6.03332 11.1917Z" fill="#042AFC" />
                    </svg>Dashboard</Link></li>
                    <li className={isActive("/user-management") ? "active" : ""}>

                        <Link to="/user-management"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M7.49996 1.66666C5.31663 1.66666 3.54163 3.44166 3.54163 5.62499C3.54163 7.76666 5.21663 9.49999 7.39996 9.57499C7.46663 9.56666 7.53329 9.56666 7.58329 9.57499C7.59996 9.57499 7.60829 9.57499 7.62496 9.57499C7.63329 9.57499 7.63329 9.57499 7.64163 9.57499C9.77496 9.49999 11.45 7.76666 11.4583 5.62499C11.4583 3.44166 9.68329 1.66666 7.49996 1.66666Z" fill="#042AFC" />
                            <path d="M11.7333 11.7917C9.4083 10.2417 5.61663 10.2417 3.27497 11.7917C2.21663 12.5 1.6333 13.4583 1.6333 14.4833C1.6333 15.5083 2.21663 16.4583 3.26663 17.1583C4.4333 17.9417 5.96663 18.3333 7.49997 18.3333C9.0333 18.3333 10.5666 17.9417 11.7333 17.1583C12.7833 16.45 13.3666 15.5 13.3666 14.4667C13.3583 13.4417 12.7833 12.4917 11.7333 11.7917Z" fill="#042AFC" />
                            <path d="M16.6583 6.11668C16.7916 7.73335 15.6416 9.15001 14.05 9.34168C14.0416 9.34168 14.0416 9.34168 14.0333 9.34168H14.0083C13.9583 9.34168 13.9083 9.34168 13.8666 9.35834C13.0583 9.40001 12.3166 9.14168 11.7583 8.66668C12.6166 7.90001 13.1083 6.75001 13.0083 5.50001C12.95 4.82501 12.7166 4.20835 12.3666 3.68335C12.6833 3.52501 13.05 3.42501 13.425 3.39168C15.0583 3.25001 16.5166 4.46668 16.6583 6.11668Z" fill="#042AFC" />
                            <path d="M18.325 13.825C18.2583 14.6333 17.7417 15.3333 16.875 15.8083C16.0417 16.2667 14.9917 16.4833 13.95 16.4583C14.55 15.9167 14.9 15.2417 14.9667 14.525C15.05 13.4917 14.5583 12.5 13.575 11.7083C13.0167 11.2667 12.3667 10.9167 11.6583 10.6583C13.5 10.125 15.8167 10.4833 17.2417 11.6333C18.0083 12.25 18.4 13.025 18.325 13.825Z" fill="#042AFC" />
                        </svg> User Management
                        </Link>

                    </li>
                    <li className={isActive("/notified-user-management") ? "active" : ""}>

                        <Link to="/notified-user-management"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M7.49996 1.66666C5.31663 1.66666 3.54163 3.44166 3.54163 5.62499C3.54163 7.76666 5.21663 9.49999 7.39996 9.57499C7.46663 9.56666 7.53329 9.56666 7.58329 9.57499C7.59996 9.57499 7.60829 9.57499 7.62496 9.57499C7.63329 9.57499 7.63329 9.57499 7.64163 9.57499C9.77496 9.49999 11.45 7.76666 11.4583 5.62499C11.4583 3.44166 9.68329 1.66666 7.49996 1.66666Z" fill="#042AFC" />
                            <path d="M11.7333 11.7917C9.4083 10.2417 5.61663 10.2417 3.27497 11.7917C2.21663 12.5 1.6333 13.4583 1.6333 14.4833C1.6333 15.5083 2.21663 16.4583 3.26663 17.1583C4.4333 17.9417 5.96663 18.3333 7.49997 18.3333C9.0333 18.3333 10.5666 17.9417 11.7333 17.1583C12.7833 16.45 13.3666 15.5 13.3666 14.4667C13.3583 13.4417 12.7833 12.4917 11.7333 11.7917Z" fill="#042AFC" />
                            <path d="M16.6583 6.11668C16.7916 7.73335 15.6416 9.15001 14.05 9.34168C14.0416 9.34168 14.0416 9.34168 14.0333 9.34168H14.0083C13.9583 9.34168 13.9083 9.34168 13.8666 9.35834C13.0583 9.40001 12.3166 9.14168 11.7583 8.66668C12.6166 7.90001 13.1083 6.75001 13.0083 5.50001C12.95 4.82501 12.7166 4.20835 12.3666 3.68335C12.6833 3.52501 13.05 3.42501 13.425 3.39168C15.0583 3.25001 16.5166 4.46668 16.6583 6.11668Z" fill="#042AFC" />
                            <path d="M18.325 13.825C18.2583 14.6333 17.7417 15.3333 16.875 15.8083C16.0417 16.2667 14.9917 16.4833 13.95 16.4583C14.55 15.9167 14.9 15.2417 14.9667 14.525C15.05 13.4917 14.5583 12.5 13.575 11.7083C13.0167 11.2667 12.3667 10.9167 11.6583 10.6583C13.5 10.125 15.8167 10.4833 17.2417 11.6333C18.0083 12.25 18.4 13.025 18.325 13.825Z" fill="#042AFC" />
                        </svg>Notified User
                        </Link>

                    </li>
                    {/* <li className={isActive("/settings") ? "active" : ""}>
                        <Link>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                            <path d="M15.75 5.68333C14.2416 5.68333 13.625 4.61666 14.375 3.30833C14.8083 2.55 14.55 1.58333 13.7916 1.15L12.35 0.324998C11.6916 -0.0666688 10.8416 0.166665 10.45 0.824998L10.3583 0.983331C9.60829 2.29166 8.37496 2.29166 7.61663 0.983331L7.52496 0.824998C7.14996 0.166665 6.29996 -0.0666688 5.64163 0.324998L4.19996 1.15C3.44163 1.58333 3.18329 2.55833 3.61663 3.31666C4.37496 4.61666 3.75829 5.68333 2.24996 5.68333C1.38329 5.68333 0.666626 6.39166 0.666626 7.26666V8.73333C0.666626 9.6 1.37496 10.3167 2.24996 10.3167C3.75829 10.3167 4.37496 11.3833 3.61663 12.6917C3.18329 13.45 3.44163 14.4167 4.19996 14.85L5.64163 15.675C6.29996 16.0667 7.14996 15.8333 7.54163 15.175L7.63329 15.0167C8.38329 13.7083 9.61663 13.7083 10.375 15.0167L10.4666 15.175C10.8583 15.8333 11.7083 16.0667 12.3666 15.675L13.8083 14.85C14.5666 14.4167 14.825 13.4417 14.3916 12.6917C13.6333 11.3833 14.25 10.3167 15.7583 10.3167C16.625 10.3167 17.3416 9.60833 17.3416 8.73333V7.26666C17.3333 6.4 16.625 5.68333 15.75 5.68333ZM8.99996 10.7083C7.50829 10.7083 6.29163 9.49166 6.29163 8C6.29163 6.50833 7.50829 5.29166 8.99996 5.29166C10.4916 5.29166 11.7083 6.50833 11.7083 8C11.7083 9.49166 10.4916 10.7083 8.99996 10.7083Z" fill="#042AFC" />
                        </svg> Settings </Link>
                    </li> */}
                </ul>
                <div className="sidebar-footer">
                    <img src={require("../assets/images/logout-image-bottom.svg").default} />
                    <p>Are you sure you want to <span>log out?</span> </p>
                    <Button variant="unset" onClick={handleLogout}>Logout</Button>
                </div>
            </section>
        </>
    );
};
export default Sidebar;